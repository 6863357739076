import React from "react"
import PropTypes from "prop-types"

import imageUrl from "lib-vinomofo/utils/image-url"

import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"
import classNames from "lib-vinomofo/utils/class-names"

import "./legacy-content.scss"

const breakpoints = [Breakpoint("legacy-content--large", [720, null])]

const replaceS3BucketImageSources = htmlString => {
  // specifically replace old images uploaded from the vinofiles admin into the s3 bucket
  const imageSrcPattern = /src="(https:\/\/s3-ap-southeast-2\.amazonaws\.com\/media-vinofiles-io[^"]*(jpg|png))"/g

  return htmlString.replace(imageSrcPattern, (_, p1) => {
    const src = imageUrl(p1, { width: 720 })
    return `src="${src}"`
  })
}

const LegacyContent = ({ body }) => {
  const [breakpoint, breakpointRef] = useWidthBreakpoints(breakpoints)

  const classes = classNames(["legacy-content", breakpoint])

  const htmlContent = replaceS3BucketImageSources(body)
  let htmlContentWithoutCheckoutLinks = htmlContent
  if(process.env.GATSBY_SITE_MARKET === "SG") {
    htmlContentWithoutCheckoutLinks = htmlContent.replace(
      /<p>\n?<b>Check out: ?<\/b>\n?<a ?\n?href="https:\/\/www\.vinomofo\.com\/[^"]*">\n?<b>[^<]*<\/b>\n?<\/a>\n?<b>\n?<\/b>\n?<\/p>/g,
      ""
    ).replace(
      /<p>\n?<b>Check out: ?<\/b>\n?<a ?\n?href="https:\/\/www\.vinomofo\.com\/[^"]*">\n?<b>[^<]*<\/b>\n?<\/a>\n?<\/p>/g,
      ""
    ).replace(
      /<p>\n?<b>Check out: ?<\/b>\n?<a ?\n?href="https:\/\/www\.vinomofo\.com\/[^"]*">[^<]*<\/a>\n?<\/p>/g,
      ""
    ).replace(
      /https:\/\/www\.vinomofo\.com\//g, "https://www.vinomofo.com.sg/"
    ).replace(/http:\/\/www\.vinomofo\.com\//g, "https://www.vinomofo.com.sg/")
  }

  return (
    <div
      ref={breakpointRef}
      className={classes}
      dangerouslySetInnerHTML={{ __html: htmlContentWithoutCheckoutLinks }}
    />
  )
}

LegacyContent.propTypes = {
  body: PropTypes.string.isRequired,
}

export default LegacyContent
