import React from "react"
import PropTypes from "prop-types"

import {
  useWidthBreakpoints,
  Breakpoint,
} from "lib-vinomofo/hooks/use-breakpoints"
import classNames from "lib-vinomofo/utils/class-names"
import distanceInWordsToNow from "date-fns/distance_in_words_to_now"

import styles from "./article-header.module.scss"
import defaultAuthorImage from "./default-author-image.svg"

import imageUrl from "lib-vinomofo/utils/image-url"

const breakpoints = [
  Breakpoint("medium", [550, 759]),
  Breakpoint("large", [760, null]),
]

const ArticleHeader = ({ title, author, publishDate, readingTime, image }) => {
  const [breakpoint, breakpointRef] = useWidthBreakpoints(breakpoints)

  const classes = classNames([styles.articleHeader, styles[breakpoint]])

  return (
    <div ref={breakpointRef} className={classes}>
      <div
        className={styles.hero}
        style={{
          backgroundImage: `url("${imageUrl(image, {
            height: 722,
            fit: "crop",
          })}")`,
        }}
      >
        <h1 className={styles.title}>{title}</h1>
      </div>

      <div className={styles.header}>
        <div className={styles.meta}>
          <div className={styles.authorImage}>
            <img
              alt={author.name}
              src={
                (author.image &&
                  imageUrl(author.image, {
                    height: 72,
                    width: 72,
                    fit: "crop",
                    crop: "faces",
                  })) ||
                defaultAuthorImage
              }
            />
          </div>
          <div className={styles.authorName}>
            By <strong>{author.name}</strong>
          </div>
          <div className={styles.publishDate}>
            {distanceInWordsToNow(publishDate, { addSuffix: true })}
          </div>
          <div className={styles.readingTime}>{readingTime}</div>
        </div>
      </div>
    </div>
  )
}

ArticleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  author: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  readingTime: PropTypes.string.isRequired,
  publishDate: PropTypes.string.isRequired,
}

export default ArticleHeader
