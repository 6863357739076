import React from "react"

import Helmet from "react-helmet"
import { withPrefix } from "gatsby-link"

import PageSection from "lib-vinomofo/components/page-section"
import imageURL from "lib-vinomofo/utils/image-url"

import PageLayout from "../components/page-layout"
import ArticleHeader from "../components/article-header"
import LegacyContent from "../components/legacy-content"
import ArticleGrid from "../components/article-grid"

import {
  useMarketContext,
  hreflangTags,
} from "lib-vinomofo/contexts/market-context"

export default ({ pageContext }) => {
  const market = useMarketContext()

  const { post, recentPosts, siteMetadata } = pageContext

  const {
    slug,
    title,
    body,
    thumbnail,
    image,
    author,
    readingTime,
    publishDate,
    updatedAt,
    excerpt,
    metaDescription,
  } = post

  const pageTitle = `${title} | ${siteMetadata.title} ${market.name}`
  const pageUrl = `${siteMetadata.siteUrl}${withPrefix(slug)}/`
  const canonicalUrl = `${market.canonical_url}${withPrefix(slug)}/`

  return (
    <PageLayout>
      <Helmet>
        <title>{pageTitle}</title>

        {hreflangTags.map(tag => {
          return (
            <link
              key={tag.lang}
              rel="alternate"
              href={`${tag.href}${withPrefix(slug)}/`}
              hreflang={tag.lang}
            />
          )
        })}

        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={metaDescription || excerpt} />
        <meta property="og:site_name" content={pageTitle} />
        <meta name="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription || excerpt} />
        <meta property="og:url" content={pageUrl} />
        <meta
          property="og:image"
          content={imageURL(thumbnail, { width: 1200, height: 628 })}
        />
        <meta property="article:published_time" content={publishDate} />
        <meta property="article:modified_time" content={updatedAt} />
        <meta property="article:tag" content={null} />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/vinomofo"
        />
        <meta
          property="article:author"
          content="https://www.facebook.com/vinomofo"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title} />
        <meta
          property="twitter:description"
          content={metaDescription || excerpt}
        />
        <meta property="twitter:url" content={pageUrl} />
        <meta
          property="twitter:image"
          content={imageURL(thumbnail, {
            width: 600,
            fit: "crop",
          })}
        />
        <meta property="twitter:label1" content="Written by" />
        <meta property="twitter:data1" content={author.name} />
        <meta property="twitter:site" content="@vinomofo" />
        <meta property="twitter:creator" content={market.twitter} />
      </Helmet>

      <ArticleHeader
        title={title}
        author={author}
        publishDate={publishDate}
        readingTime={readingTime}
        image={image}
      />
      <LegacyContent body={body} />

      <PageSection spaceBefore={2}>
        <ArticleGrid articles={recentPosts} />
      </PageSection>
    </PageLayout>
  )
}
